<template>
  <div>
    <Title />
    <div class="app-content-box bg-gray profile-comments">
      <div class="container wrapper-book">
        <h2 class="top-heading-big">
          Dodaj książkę na półkę
        </h2>

        <div class="row mt-4">
          <div class="col-6">
            <img
              :src="coverUrl + book.image"
              alt=""
              class="w-100"
            />
          </div>
          <div class="col-6 pl-0">
            <h3 class="book-side-title">{{book.tytul}}</h3>
            <p class="book-side-author">{{book.autor}}</p>
            <!-- <a href="#" class="link-small-underline mt-4"
              >150 dostępnych wydań</a
            > -->
          </div>
        </div>
      </div>

      <div class="book-form-container">
        <div class="form-group-label no-border-top no-border-bottom">
          <div class="container form-container">
            <strong>Określ status książki</strong>
          </div>
        </div>
        <div
          class="form-group form-group-border"
          v-for="(e, key) in status"
          :key="key"
        >
          <div class="container form-container">
            <div class="form-radio">
              <input type="radio" name="radio_" :id="'radio' + e.id" :value="e.stat" v-model="checkedStatus" />
              <label :for="'radio' + e.id">{{ e.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="book-form-container" v-if="topShelves.length">
        <div class="form-group-label">
          <div class="container form-container">
            Wybierz top półkę:
          </div>
        </div>
        <div
          class="form-group form-group-border"
          v-for="(e, key) in topShelves"
          :key="key"
        >
          <div class="container form-container">
            <div class="form-checkbox-switch">
              <input
                type="checkbox"
                :name="'checkbox-switch_' + e.id"
                :id="e.id"
                :value="e.id"
                :ref="'checkbox_' + e.id"
                v-model="checkedShelves"
                :disabled="e.book_count > 4"
              />
              <label :for="e.id" @click="openModal(e.id)">
                {{ e.name }}
                <span class="switch-pill">
                  <span class="switch-dot"></span>
                </span>
              </label>
            </div>
          </div>
            <ModalSlot title="Top półka" v-if="isPopupShown &&  (check_id === e.id) && e.book_count > 4" @close-modal="closeModal(false)">
              <div class="text-center mb-4">Na wybranej Top Półce masz więcej niż 5 książek. <br><br> Nie możesz dodać kolejnej książki.</div>
              <router-link :to="{name: 'Shelf', params: {id: e.id}}" replace><button class="btn-black-border w-100 mt-3 mb-1">Zobacz książki</button></router-link>
              <!-- <button class="btn-black-border w-100 mt-3 mb-1" @click="openShareModal(item.id)">Udostępni półkę</button> -->
              <button class="btn-black-full w-100 mt-3 mb-1" @click="closeModal(false)">Zamknij</button>
          </ModalSlot>
        </div>
      </div>
      <div class="book-form-container" v-if="shelves.length">
        <div class="form-group-label">
          <div class="container form-container">
            Wybierz półkę:
          </div>
        </div>
        <div
          class="form-group form-group-border"
          v-for="(e, key) in shelves"
          :key="key"
        >
          <div class="container form-container">
            <div class="form-checkbox-switch">
              <input
                type="checkbox"
                :name="'checkbox-switch_' + e.id"
                :ref="'checkbox_' + e.id"
                :id="e.id"
                :value="e.id"
                v-model="checkedShelves"
              />
              <label :for="e.id">
                {{ e.name }}
                <span class="switch-pill">
                  <span class="switch-dot"></span>
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
       <div class="book-form-container">
        <div class="container">
          <div class="form-container">
            Czy książka jest Twoja?
          </div>
        </div>
        <div class="form-group form-group-border">
          <div class="container form-container">
            <div class="form-checkbox">
              <input type="checkbox" name="checkbox_own" id="checkbox_own" :checked="isBookOwn" @click="isBookOwn = !isBookOwn"/>
              <label for="checkbox_own">
                moja książka
              </label>
            </div>
          </div>
        </div>
        <div class="form-group form-group-border" v-if="!bookLend">
          <div class="container form-container">
            <div class="form-checkbox">
              <input
                type="checkbox"
                name="checkbox_lend"
                id="checkbox_lend"
                :checked="isBookLend"
                @click="isBookLend = !isBookLend "
              />
              <label for="checkbox_lend">
                pożyczam książkę
              </label>
            </div>
          </div>
          <div v-if="isBookLend && !personLend">
            <div class="container">
            <div class="filter-wrapper mb-3">
              <input type="text" name="filterFriends" class="input-filter-friends" placeholder="Wyszukaj znajomych" v-model="search"/>
              <div class="ml-3"><i class="fas fa-search"></i></div>
            </div>
            <div class="share-shelf-wrapper">
              <div v-for="(person, key) in filtered" :key="key">
                <div class="share-shelf-list" @click="selected(person)">
                    <div class="mr-3">
                      <img :src="person.thumb_url ? plainUrl + person.thumb_url : require('../assets/avatar-placeholder.jpg')" :alt="person.username" class="share-shelf-avatar"/>
                    </div>
                    <span class="share-shelf-name">{{person.username}}</span>
                </div>
              </div>
            </div>
            </div>
          </div>
          <div v-if="isBookLend">
            <div class="">
            <div class="container selected-wrapper mb-3" v-if=" personLend">
            <img :src="plainUrl + personLend.thumb_url" :alt="personLend.username" />
              <input type="text" name="filterFriends" class="" v-model="personLend.username"/>
                <span class="ml-2" @click="personLend = ''">&#10006;</span>
              </div>
              <ValidationObserver ref="observer" tag="form">
                <div class="container selected-information">
                <div class="lend-title mb-2 mt-3"><b>Podaj dane</b> (obowiązkowe)</div>
                <ValidationProvider rules="required" v-slot="{errors}"  class="w-100" v-if="!personLend">
                  <input type="text" v-model="fname" placeholder="Imię" />
                  <span class="form-error-info text-center">{{ errors[0] }}</span>
                </ValidationProvider>
                  <ValidationProvider rules="required" v-slot="{errors}"  class="w-100" v-if="!personLend">
                    <input type="text" v-model="lname" placeholder="Nazwisko" />
                    <span class="form-error-info text-center">{{ errors[0] }}</span>
                  </ValidationProvider>
                    <div class="w-100 d-flex justify-content-between">
                    <ValidationProvider
                      :rules="{
                        required: true,
                        regex: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
                        checkDate: true }"
                      v-slot="{errors}"  class="w-100">
                      <input type="text" placeholder="rrrr-mm-dd" class="w-100" v-model="date"/>
                      <span class="form-error-info text-center mb-3">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div class="form-calendar-icon ml-3" @click="selectCalendar = !selectCalendar"></div>
                    </div>
                    <div class="d-flex justify-content-center pb-3">
                      <DatePicker
                      mode="single"
                      v-model="date"
                      color="gray"
                      v-show="selectCalendar"
                      :model-config="modelConfig"
                      :min-date="new Date(this.today)"
                      >
                      </DatePicker>
                    </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
         <!-- <transition name="fade">
            <div class="container form-container" v-if="isBookLend">
              <div class="input-container">
                <input type="text" class="form-input w-100" placeholder="imię*" v-model="name" />
              </div>
              <div class="input-container">
                <input
                  type="text"
                  class="form-input w-100"
                  placeholder="nazwisko*"
                  v-model="surname"
                />
              </div>
              <div class="input-container">
                <div class="form-calendar">
                  <DatePicker
                  mode="single"
                  :value="selectedDate"
                  color="gray"
                  :input-props='{placeholder: "wybierz termin oddania", class: "form-input form--calendar w-100"}'
                  :attributes='attributes'
                  >
                  </DatePicker>
                </div>
              </div>
              <p class="input-info">*Obowiązkowe</p>
            </div>
          </transition> -->
        </div>
      </div>
    <div class="form-group form-group-footer wrapper mb-5">
      <div class="container form-container">
        <button type="submit" class="btn-black-full w-100" @click="addBookToShelf">Zapisz</button>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Title from '../components/Title'
import ModalSlot from '../components/ModalSlot'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, regex } from 'vee-validate/dist/rules'

let tempdate = new Date()
let day = tempdate.getDate()
if (tempdate.getDate() < 10) {
  day = '0' + day
}
tempdate = tempdate.getFullYear() + '-' + (tempdate.getMonth() + 1) + '-' + day
extend('required', {
  ...required,
  message: 'Pole jest obowiązkowe'
})
extend('regex', {
  ...regex,
  message: 'Poprawny format danych to: rrrr-mm-dd'
})
extend('checkDate', {
  validate: value => value >= tempdate,
  message: 'Data musi być datą przyszłą'
})
export default {
  name: 'BorrowBook',
  props: {
    id: [String, Number]
  },
  components: {
    Title,
    ModalSlot,
    DatePicker,
    ValidationObserver,
    ValidationProvider
  },
  async created () {
    await this.$https('/book/' + this.id, {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.book = data.response.book
        this.activeShelves = data.response.book.shelfs
        this.activeShelves.forEach(e => {
          this.checkedShelves.push(e.id)
          if (e.name === 'Pożyczone') {
            this.bookLend = true
          }
          if (e.name === 'Na później') {
            this.checkedStatus = 'later'
          } else if (e.name === 'Czytam') {
            this.checkedStatus = 'read'
          } else if (e.name === 'Przeczytane') {
            this.checkedStatus = 'readed'
          } else if (e.name === 'Chcę przeczytać') {
            this.checkedStatus = 'want'
          }
        })
      })
      .catch(error => {
        console.log('', error)
      })

    await this.$https('/shelf', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true,
      headers: {
      }
    })
      .then(({ data }) => {
        this.isLoaded = true
        this.tempShelfArray = data.response
        for (const element of this.tempShelfArray) {
          if (element.type === 'top') {
            this.topShelves.push(element)
          } else if (element.type === 'user' || element.name.includes('Chcę przeczytać w')) {
            this.shelves.push(element)
          }
        }
      })
      .catch(error => {
        console.log('', error)
      })
    this.today = new Date()
    this.today = this.today.getFullYear() + '-' + (this.today.getMonth() + 1) + '-' + this.today.getDate()
    this.today = this.today.replace(/-/gi, ', ')
    this.today = this.today.replace(/, 0/gi, ', ')
  },
  computed: {
    filtered () {
      if (this.search.length < 3) return ''
      else {
        return this.friendsList.filter(x => {
          return x.username.toLowerCase().includes(this.search.toLowerCase())
        })
      }
    }
  },
  data () {
    return {
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD' // Uses 'iso' if missing
      },
      date: '',
      today: '',
      isPopupShown: false,
      tempShelfArray: [],
      selectCalendar: false,
      fname: '',
      lname: '',
      check_id: 0,
      bookLend: false,
      book: [],
      activeShelves: [],
      personLend: '',
      shelves: [],
      topShelves: [],
      checkedShelves: [],
      friendsList: [],
      name: '',
      surname: '',
      search: '',
      checkedStatus: 'want',
      selectedDate: '',
      isBookLend: false,
      isBookOwn: false,
      attributes: [
        {
          key: 'today',
          highlight: true,
          dates: new Date()
        }
      ],
      status: [
        {
          id: 1,
          stat: 'want',
          name: 'chcę przeczytać'
        },
        {
          id: 2,
          stat: 'readed',
          name: 'przeczytana'
        },
        {
          id: 3,
          stat: 'read',
          name: 'aktualnie czytam'
        },
        {
          id: 4,
          stat: 'later',
          name: 'zapisz na później'
        }
      ]
    }
  },
  watch: {
    checkedShelves: function (val) {
    },
    search: function (val) {
      if (val.length > 2) {
        this.filterUsers(val)
      }
    }
  },
  methods: {
    selected (person) {
      this.personLend = person
    },
    filterUsers (item) {
      this.$https('/search/user?user=' + item, {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          // this.friendsList = res.data.response
          this.friendsList = data.response
        })
        .catch(error => {
          console.log('', error)
        })
    },
    closeModal () {
      this.check_id = 0
      this.isPopupShown = false
    },
    openModal (id) {
      this.check_id = id
      this.isPopupShown = true
    },
    checkTopShelfAmount (e) {
      if (e.book_count > 4) {
        alert('za dużo książek')
        this.checkedShelves.pop()
      } else {
        alert('możesz dodać książkę')
      }
    },
    async addBookToShelf () {
      const isOwned = this.isBookOwn ? 'yes' : 'no'
      const isLend = this.isBookLend ? 'yes' : 'no'
      const getData = `status=${this.checkedStatus}&own=${isOwned}&borrowed=${isLend}`
      let statusId
      this.tempShelfArray.forEach(e => {
        if (e.type === this.checkedStatus) {
          statusId = e.id
        }
      })
      if (this.checkedShelves.length) {
        this.checkedShelves.forEach(e => {
        // const getData = `status=${this.checkedStatus}&own=${isOwned}&borrowed=${isLend}`
          this.$https('book/' + this.id + '/add/' + e, {
            method: 'POST',
            mode: 'no-cors',
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: getData
          }).then(response => {
            this.$router.replace({ name: 'Shelf', params: { id: statusId } })
          })
            .catch(error => {
              console.log('', error)
            })
        })
      } else {
        this.$https('book/' + this.id + '/add/' + statusId, {
          method: 'POST',
          mode: 'no-cors',
          crossDomain: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          data: getData
        }).then(response => {
          this.$router.replace({ name: 'Shelf', params: { id: statusId } })
        })
          .catch(error => {
            console.log('', error)
          })
      }

      if (isLend === 'yes') {
        if (this.personLend) {
          const lendData = `user=${this.personLend.id}&date=${this.date}&fname=${this.fname}&lname=${this.lname}`
          this.$https('/book/' + this.id + '/lend', {
            method: 'POST',
            mode: 'no-cors',
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: lendData
          }).then(response => {
            this.$router.replace({ name: 'Shelf', params: { id: statusId } })
          })
            .catch(error => {
              console.log('', error)
            })
        } else {
          const lendData = `date=${this.date}&fname=${this.fname}&lname=${this.lname}`
          this.$https('/book/' + this.id + '/lend', {
            method: 'POST',
            mode: 'no-cors',
            crossDomain: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: lendData
          }).then(response => {
            this.$router.replace({ name: 'Shelf', params: { id: statusId } })
          })
            .catch(error => {
              console.log('', error)
            })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .wrapper-book {
    padding-top: 30px;
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
}
  .filter-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .input-filter-friends {
    width: 100%;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
  .share-shelf {
    &-wrapper {
    width: 100%;
    max-height: 50vh;
    overflow: scroll;
    }
    &-list {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    &-avatar {
      width: 50px;
      height: 50px;
    }
  }
  .book-side-option-icon {
      width: 100%;
      display: flex;
      justify-content: center;
  }
  .selected-wrapper {
    // padding: 0 15px 10px 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
    }

    input {
      width: 70%;
      border: none;
      border-bottom: 1px solid black;
      font-size: 14px;

      &:active, &:focus {
        font-size: 14px;
      }
    }
  }
  .selected-information {
    background-color: #fff;
  input {
    width: 100%;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid black;
    font-size: 14px;

    &:active, &:focus {
      font-size: 14px;
    }
  }
}
.form-error-info {
  margin: 0;
  line-height: 14px;
}
.lend-title {
  font-size: 14px;
}

.form-calendar-icon {
  position: relative;

  &::before {
    position: relative;
    height: 40px;
    width: 40px;
    text-align: center;
    font-family: 'Font Awesome 5 Free';
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
    content: '\f073';
    color: #000;
    // right: 0;
    // top: 0;
    z-index: 2;
  }

  .form-input {
    position: relative;
    padding-right: 40px;
    display: block;
    width: 100%;
    background-color: none;
    z-index: 1;
  }
}
  .classes {
   border-bottom: 1px solid red;
  }
  .share-shelf-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
</style>
